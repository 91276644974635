export const languageConstant = {
    ES: 'es',
    EN: 'en'
};
export const apiConstant = {
    API_FAILURE: "Some error occured.Please try again!",
    API_SUCCESS: "Success"
};
export const screenPathConstants = {
    LANDING_PAGE: '/',
    HOME: '/home',
    REGISTER: '/home/register',
    LOGIN: '/home/login',
    DASHBOARD: '/dashboard',
    BILLING: '/dashboard/billing',
    ABOUT_US: '/about',
    VALIDATION: '/validation',
    SUBSCRIBE: '/subscribe',
    SERVICES: '/services',
    HELP: '/help',
    KNOW_PRO: '/know-pro',
    PRIVACY_POLICY: '/politica-de-privacidad',
    TERMS_AND_CONDITION: '/terminos-y-condiciones',
    SIGNING_AUTHORITY: '/singing-authority',
    ONLY_ME_SIGNING: '/only-me-signing',
    ME_AND_OTHER_SIGNING: '/me-and-other-signing',
    OTHER_SIGNING: '/other-signing',
    USER_PROFILE: '/dashboard/profile',
    USERS: '/dashboard/users',
    DOCUMENT_HISTORY: '/dashboard/document',
    DOCUMENT_PREVIEW: '/document/preview',
    DOCUMENT_VIEW: '/document/view',
    TEMPLATES: '/dashboard/template',
    TEMPLATE_SIGNATORY: '/template/signatory',
    CORPORATE: '/admin',
    DOCUMENT_SAT_SIGNING: '/document/sat',
    HELP_AND_SUPPORT: 'help-and-support',
    CREATE_DOCUMENT: '/document/create',
    MASSIVE_SIGNATURE_LIST: '/massive-signature/list',
    MASSIVE_SIGNATURE: '/dashboard/massive-signature',
    VALIDATION_URL: '/validation-url',
    TAGS: '/dashboard/tag',
    VID_SERVICE: '/verificacion-de-identidad',
    PRICES: '/precios',
    TOKEN_LOGIN: '/auth/token',
    ENDORSEMENTS: '/endorsements/validation',
    TEMPLATE_CONFIGURATION: '/template/configuration',
    TEMPLATE_VIEW: '/template/view',
    DOCUMENT_CANCEL: '/document/cancel',
    SPECIAL_ENDORSEMENT: '/endorsements/special',
};

export const actionTypeConstant = {
    LOCALE_SET: 'LOCALE_SET',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS',
    SET_DOCUMENT: 'SET_DOCUMENT',
    SET_COUNT_DOCUMENT: 'SET_COUNT_DOCUMENT',
    SET_MULTIPLE_DOCUMENTS: 'SET_MULTIPLE_DOCUMENTS',
    SET_MASSIVE_SIGNATURE_LIST: 'SET_MASSIVE_SIGNATURE_LIST',
    SET_EXPIRE_TOKEN: 'SET_EXPIRE_TOKEN',
    SET_USER_VERIFY: 'SET_USER_VERIFY',
    SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
    CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
    SHOW_BANNER_TOP: 'SHOW_BANNER_TOP',
    CLEAR_BANNER_TOP: 'CLEAR_BANNER_TOP',
    SET_PRODUCTS: 'SET_PRODUCTS',
    SOCKET_CONNECTION: 'SOCKET_CONNECTION',
    SET_SOCKET: 'SET_SOCKET',
    SET_PAYMENT_PROCESSING: 'SET_PAYMENT_PROCESSING',
    SET_PAYMENT_COMPLETE: 'SET_PAYMENT_COMPLETE',
    SET_PAYMENT_FAILED: 'SET_PAYMENT_FAILED',
    SET_SUBUSERS: 'SET_SUBUSERS',
    SET_TOAST: 'SET_TOAST',
    SET_ENDORSEMENTS: 'SET_ENDORSEMENTS',
    SET_USER_TEMPLATES: 'SET_USER_TEMPLATES',
    SET_DEFAULT_TEMPLATES: 'SET_DEFAULT_TEMPLATES',
    SET_CURRENT_TEMPLATE_CONFIG: 'SET_CURRENT_TEMPLATE_CONFIG',
    SET_VARS_TEMPLATE_DOCUMENT: 'SET_VARS_TEMPLATE_DOCUMENT',
    REMOVE_TEMPLATE: 'REMOVE_TEMPLATE',
    SET_TEMPLATE_MASSIVE_FILES: 'SET_TEMPLATE_MASSIVE_FILES',
    SET_ONBOARDING_TARGET: 'SET_ONBOARDING_TARGET',
    SET_ONBOARDING_LIST: 'SET_ONBOARDING_LIST',
    SHOW_CUSTOM_DIALOG: 'SHOW_CUSTOM_DIALOG',
    CLEAR_CUSTOM_DIALOG: 'CLEAR_CUSTOM_DIALOG',
};

export const menuConstant = {
    LANDING_PAGE: '/',
    SIGN_UP: 'Sign Up',
    ABOUT_US: 'Acerca de',
    VALIDATION: 'Validación',
    HELP: 'Ayuda',
    KNOW_PRO: 'weetrust PRO',

};
export const contentConstant = {
    WHO_SIGNS: '¿Quién firma?',
    ONLY_ME: 'Sólo Yo',
    OTHERS_AND_ME: 'Otros y yo',
    OTHERS: 'Otros',
    MEXICO: 'México',
    COUNTRY: 'país',
    ELECTRONIC_SIGNATURE: 'Firma Electrónica',
    E_SIGNATURE: 'e.firma',

};
export const countryConstant = {
    MEXICO: 'Mexico',
    PAIS: 'País',
    COUNTRY: 'Country',
    USA: 'USA',
    CANADA: 'Canada'

};
export const httpConstants = {
    METHOD_TYPE: {
        POST: 'POST',
        PUT: 'PUT',
        GET: 'GET',
        DELETE: 'DELETE',
    },
    CONTENT_TYPE: {
        APPLICATION_JSON: 'application/json',
        MULTIPART_FORM_DATA: 'multipart/form-data',
        APPLICATION_FORM_URLENCODED: 'application/x-www-form-urlencoded',
        IMAGE_PNG: 'image/png'
    },
    DEVICE_TYPE: {
        WEB: 'web'
    },
    API_END_POINT: {
        DOCUMENT: '/api/document',
        DOCUMENTS_TAGS: '/api/documents/tags',
        MULTIPLE_DOCUMENT: '/api/multiple-document',
        GENERATE_DOCUMENT: '/api/document/generate',
        GET_TEMPLATE: '/api/document/template',
        GET_DOCUMENT: '/api/get-document',
        GET_DOCUMENT_SHARED: '/api/get-document-shared',
        SHARED_DOCUMENT: '/api/shared-document',
        VALIDATE_DOCUMENT: '/api/document/validate',
        SIGN_DOCUMENT: '/api/documents/sign',
        MASSIVE_SIGN_DOCUMENT: '/api/documents/massive/sign',
        SAT_SIGN_DOCUMENT: '/api/documents/sign/sat',
        GENERATE_API_KEY: '/api/ApiKey',
        SEARCH_DOCUMENT: '/api/documents/search',
        //SEARCH_BY_EMAIL: '/api/subscription/email',
        ADVANCED_SEARCH: '/api/documents/advanced-search',
        PENDING_DOCUMENTS: '/api/documents/pendings',
        COUNT_PENDING_DOCUMENTS: '/api/documents/pendings/count',
        ADVANCED_SEARCH_EMAILS: '/api/documents/advanced-search/emails',
        ADVANCED_SEARCH_EXPORT_EXCEL: '/api/document/advanced-search/export-excel',
        ADVANCED_SEARCH_BULK_DOWNLOAD_DOCUMENTS: '/api/document/advanced-search/download',
        DOCUMENT_DOCUMENT_ID: '/api/document/document-id',
        DELETE_DOCUMENT: '/api/document/delete',
        SAVE_SIGNATORIES: '/api/document/save-signatories',
        DRAW_DOCUMENT: '/api/document/draw',
        UPDATE_SIGNATORY: '/api/document/update-signatory',
        RESEND_EMAIL: '/api/document/resend-email',
        RESEND_WHATSAPP: '/api/document/resend-whatsapp',
        RATINGS: '/api/ratings',
        REMOVE_SUB_USER: '/api/sub-user/',
        UPDATE_SUB_USER: '/api/sub-user',
        SEND_EMAIL: '/api/document/send-mail',
        SHARE_DOCUMENT: '/api/document/share',
        SHARE_OBSERVERS: '/api/document/share-observer',
        BILLING_REQUEST: '/api/billing/request',
        SUBSCRIPTION_PLANS: '/api/subscription/plan',
        SUBSCRIPTION_ENDPOINT: '/api/subscription/',
        //SUBSCRIPTION: '/api/subscription',
        SAVE_CARD: '/api/card',
        GET_CARD: '/api/cards/',
        SUBSCRIBE_USER: '/api/subscribe/user',
        UNSUBSCRIBE_USER: '/api/unsubscribe/user',
        SIGN_SVG_COORDINATES: '/api/signature/svg/coordinates',
        //GET_COUNT_DOCUMENT: '/api/document/count',
        //GET_STATS_DOCUMENT: '/api/documents/stats',
        VALIDATE_BIOMETRIC_ID: '/api/biometric/card/validate',
        VALIDATE_BIOMETRIC_ID_AND_FACE: '/api/biometric/card-and-face/validate',
        VALIDATE_BIOMETRIC_PASSPORT: '/api/biometric/passport/validate',
        IDENTITY_PROCESS: '/api/biometric/identity-process',
        CHECK_BIOMETRIC_ATTEMPTS: '/api/biometric/checkAttempts',
        CHECK_BIOMETRIC_CREDITS: '/api/biometric/checkCredits',
        USE_BIOMETRIC_CREDITS: '/api/biometric/useCredits',
        RETRY_BIOMETRIC: '/api/biometric/retry',
        IDENTITY_RETRY: '/api/biometric/identity-retry',
        GET_BIOMETRIC_DOCUMENT: '/api/biometric/document',
        SAVE_FORMAT: '/api/format',
        /** DELETE? */
        GET_ENTITY_LIST: '/get-entity-list',
        GET_ENTITY_LIST_BY_QUERY_OBJ: '/get-entity-list-by-query-obj',
        //VALIDATE_CERTIFICATE: '/sat/getCertificate.php',
        TAGS: '/api/tag',
        GET_TEMPLATES: '/api/template',
        GET_TEMPLATES_CONFIGURABLE: '/api/templatesV2',
        GET_VARIABLES_TEMPLATE_BY_DOC: '/api/templates/uploadDocx',
        GENERATE_CSV: '/api/templates/generate-csv',
        CREATE_TEMPLATE_BY_DOC: '/api/templates/create',
        TEMPLATE_EDIT: '/api/templates/edit',
        TEMPLATE_DELETE: '/api/templates/delete',
        GENERATE_TEMPLATESV2: '/api/templates/generateDocumentByTemplateV2',
        TEMPLATE_DEFAULT: '/api/templates/default',
        VALIDATE_URL: '/api/validate-url',
        USER_LOGIN_ATTEMPT: '/api/user/auth-attempt',
        USER_LOGIN: '/api/user/auth',
        USER_LOGOUT: '/api/user/logout',
        USER_PASSWORD_LOST_EMAIL: '/api/user/password-lost-email',
        USER_PASSWORD_RESET: '/api/user/password-reset',
        USER_SIGN_UP: '/api/user/sign-up',
        USER_VERIFY: '/api/user/verify',
        USER_UPDATE_PERSONAL_DATA: '/api/user/update-personal-data',
        USER_ADD_SIGN: '/api/user/add-sign',
        USER_CHANGE_SIGN: '/api/user/change-sign',
        USER_UPDATE_PASSWORD: '/api/user/password-update',
        USER_ADDED_SUB_USER: '/api/user/subuser-sign-up',
        USER_LEGAL_POLICY: '/api/policy',
        FORM_DOCUMENT: '/api/form/document',
        IDENTITY_REQUEST: '/api/identity',
        IDENTITY_REQUEST_V2: '/api/v2/identity',
        IDENTITY_REQUEST_PROCESS: '/api/identity/validation/request/process',
        IDENTITY_VERIFICATION_FACE_LOGIN: '/api/v2/identity/facelogin/verification',
      IDENTITY_VERIFICATION_GENERATE_VALIDATION_ID: '/api/v2/identity/generate/validation',
      IDENTITY_VERIFICATION_ALIVE_PRO_TOKEN: '/api/v2/identity/alive-pro',
        VOBO: '/api/vobo',
        MASSIVE_ENDORSEMENTS_TO_SIGN: '/api/documents/endorsement-pendings'

        //ADMIN_CHANGE_CREDITS: '/api/admin/credits',
        //ADMIN_CHANGE_CONFIG: '/api/admin/config',
    }
};
export const errorConstants = {
    LAST_AND_NEW_PASSWORD_CAN_NOT_SAME: "profile.last_and_new_password_not_same",
    CHANGE_PASSWORD_FAIL: "profile.change_password_fail",
    CHANGE_PASSWORD_ERROR: "profile.change_password_error",
    LOGIN_PASSWORD_ERROR: "login.password_error",
    FILL_FIELD_MESSAGE: "profile.fill_field_message",
    NUMBER_LENGTH_MESSAGE: "addUser.number_length_message",
    LINK_EXPIRE_MESSAGE: "expire_link_message",
    LINK_INVALID_MESSAGE: "invalid_link_message",
    DELETED_DOCUMENT_MESSAGE: "deleted_document_message",
    PASSWORD_EMPTY: "register.password_didnt_empty",
    PASSWORD_AND_CONFIRM_PASSWORD: "register.password_didnt_match",
    INVALID_PASSWORD: 'login.invalid_password',
    RECAPTCHA_REQUIRED: 'recaptcha.required',
    RECAPTCHA_EXPIRED: 'recaptcha.expired',
    USER_NOT_FOUND: 'login.user_not_found',
    WRONG_CREDENTIALS: 'login.wrong.credencials',
    TOO_MANY_REQUEST: 'login.too_many_request',
    GENERIC_ERROR: 'generic.error',
    ARGUMENT_ERROR: 'login.argument_error',
    REQUIRED_FIELD: 'login.fill_out_fields',
    EMAIL_NOT_VERIFIED: 'login.email_not_verified',
    NO_SIGNATORY: 'preview.file.no.signatory',
    MISSING_FIXED_SIGNATURE: 'preview.file.fixed.signatory.missing.emails',
    MISSING_SIGNATORY_NAME: 'preview.file.signatory.name.missing',
    MISSING_SIGNATORY_EMAIL: 'preview.file.signatory.email.missing',
    MISSING_SIGNATORY_WHATSAPP: 'preview.file.signatory.whatsapp.missing',
    INVALID_SIGNATORY_WHATSAPP: 'preview.file.signatory.whatsapp.invalid',
    SAME_OWNER_SIGNATORY_EMAIL: 'preview.file.same_signatory_and_owner.email',
    MISSING_SIGNATORY_NAME_AND_EMAIL: 'preview.file.signatory.name_and_email.missing',
    MISSING_MAIL_TITLE: 'preview.file.missing.mail.title',
    USER_ALREADY_EXISTS: 'addUser.user.exist',
    LIMIT_SUBUSER: 'addSubUser.limit',
    FAILED_TO_UPDATE_USER: 'user.update.failed',
    INVALID_EMAIL_ADDRESS: 'addUser.invalid_email_address',
    PASSWORD_LENGTH: 'addUser.password_length',
    RESET_PASSWORD_FAILURE: 'reset.password.failure',
    DOCUMENT_PROCESS_ERROR: 'document.upload.error',
    PASSWORD_STRENGTH_ERROR: 'Must contain one number, one uppercase, one lowercase, and at least 8 characters',
    DOCUMENT_SIGNATURE_ERROR: 'document.signature.error',
    SAVE_CARD_ERROR: 'The card has been declined.',
    SAVE_CARD_ERROR_ES: 'La tarjeta ha sido rechazada.',
    CREATE_TOKEN_ERROR: 'Invalid card details',
    CREATE_TOKEN_ERROR_ES: ' Datos de la tarjeta inválidos',
    PAYMENT_ERROR: 'Payment has been declined, Please try again.',
    PAYMENT_ERROR_ES: 'El pago ha sido rechazado, por favor intente de nuevo.',
    GEOLOCATION_PREVIEW_ERROR: 'document.geolocation.preview.error',
    GEOLOCATION_ERROR: 'document.geolocation.error',
    SHARED_EMPTY_ERROR: 'document.shared.empty.error',
    SHARED_EMPTY_EMAIL_ERROR: 'document.shared.empty.email',
    SHARED_DUPLICATE_EMAIL_ERROR: 'document.shared.duplicate.email',
    SHARED_EMAIL_FORMAT_ERROR: 'document.shared.format.email',
    REPEATED_EMAIL_ERROR: 'preview.file.signatory.email.repeated',
    SIGNATORY_USER_NOT_MATCH: 'signatory.user.not.match',

    SPLIT_EMPTY_STATIC_SIGNATORY_ERROR: 'document.split.static.sign',

    BILLING_BUSINESS_NAME: 'billing.business_name',
    BILLING_RFC: 'billing.RFC',
    BILLING_ADDRESS: 'billing.address',
    BILLING_STATE: 'billing.state',
    BILLING_CITY: 'billing.city',
    BILLING_ZIP_CODE: 'billing.zipCode',
    BILLING_COUNTRY: 'billing.country',
    BILLING_PHONE: 'billing.phone',
    INVALID_NUMBER: 'invalid.number',
    INVALID_KEY_PASSWORD: 'validate.invalid.password',
    INVALID_CERT: 'validate.invalid.cert',
    INVALID_KEY_PAIR: 'validate.invalid.pair',
    EXPIRED_CERTIFICATES: 'validate.expired.certificate',
    SIGN_USING_LEGAL_REPRESENTATIVE: 'validate.legal.representative',
    INVALID_CERTIFICATE_OR_PRIVATE_KEY: 'validate.invalid.password',
    MISSING_CERTIFICATE_OR_PRIVATE_KEY: 'validate.missing.certificate.key',
    SERVER_ERROR: 'server.error',
    DOCUMENT_SIGNED_SUCCESS: 'validate.document.signed.success',
    SIGNATORY_DOCUMENT_SIGNED_SUCCESS: 'signatory.document.signed.success',
    SIGNATURE_IS_MANDATORY: 'document.signature.error',
    PROFILE_COMPANY_NAME: 'profile.companyName',
    PROFILE_ACTIVITY: 'profile.activity',
    PROFILE_INDUSTRY: 'profile.industry',
    PROFILE_DOB: 'profile.dob',
    EMAIL_EXISTS: "The email address is already in use by another account.",
    EMAIL_EXISTS_ES: "La dirección de correo electrónico ya está en uso por otra cuenta.",
    INVALID_REGISTER: "Invalid Register",
    INVALID_REGISTER_ES: "Registro no valido",

    TEMPLATE_UNKNOWN_ERROR: "Ocurrio un error, intente de nuevo mas tarde.",
    TEMPLATE_DOCUMENT_LIMIT_EXCEEDED: "No se cuenta con documentos disponibles",
    TEMPLATE_BIOMETRIC_CREDITS_NOT_SUFFICIENT: "No se cuenta con creditos de biometría suficientes.",
    TEMPLATE_BACKGROUNDCHECKS_CREDITS_NOT_SUFFICIENT: "No se cuenta con creditos de BackgroundChecks disponibles",

};
export const validationConstants = {
    MAX_SIZE: 20971520, //maximum size of document to upload 20 MB
    VALIDATE_PDF_MAX_SIZE: 31457280, //maximun size to validate pdf documents
    MAX_CSV_SIZE: 20971520, //maximium size of csv for templates ( 20 MB )
    MAX_COUNT_MULTIPLE_FILES: 10,
    MAX_IMAGE_SIZE: 20971520, //maximium size of images  ( 20 MB )
    ACCEPTED_FILES: {
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/pdf': ['.pdf'],
    },
    ACCEPTED_ONLY_PDF_FILES: {
        'application/pdf': ['.pdf'],
    },
    ACCEPTED_PDF_AND_ASN_FILES: {
      'application/pdf': ['.pdf'],
      'text/plain': ['.asn'],
    },
    ACCEPTED_ONLY_WORD_FILES: {
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    ACCEPTED_ONLY_CSV: {
        'text/csv': ['.csv'],
    },
    ACCEPTED_ONLY_IMAGES: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
    },
    ACCEPTED_SAT_CER: {
        'application/octet-stream': ['.cer'],
    },
    ACCEPTED_SAT_KEY: {
        'application/octet-stream': ['.key'],
    },
};
export const genericConstants = {
    CERTIFICATE: 'cert',
    PRIVATE_KEY: 'key',
    PLAN_NAME: {
        GO_GREEN: "Go Green",
        FREE: "Free",
        CORPORATE: "Corporate",
        TRIAL_1: "TRIAL_1",
        BASE_MEMBERSHIP: "BASE_MEMBERSHIP",
        DYNAMIC_MEMBERSHIP: "DYNAMIC_MEMBERSHIP",
        ZERO: "ZERO",
        DEBTOR: "DEBTOR",
    },
    CORPORATE_MEMBERSHIPS: {
        M1: 'M1',
        M2: 'M2',
        M3: 'M3',
        M4: 'M4',
        M5: 'M5',
        M6: 'M6',
    },
    FIELD_TYPE: {
        TEXT: "TEXT",
        DATE: "DATE",
        SIGNATURE: "SIGNATURE",
        CHECK_MARK: "CHECKBOX",
        STATIC_SIGN: "STATIC_SIGN",
    },
    SIGNATURE_TYPE: {
        E_FIRMA: "E_FIRMA",
        ELECTRONIC_SIGNATURE: "ELECTRONIC_SIGNATURE",
    },
    DOCUMENT_STATUS: {
        PENDING: 'pending',
        DRAFT: 'draft',
        COMPLETED: 'completed',
        SHARED: 'shared',
    },
    DOCUMENT_SIGN_TYPE: {
        ONLY_ME: "ONLY_ME",
        ME_AND_OTHERS: "ME_AND_OTHERS",
        OTHERS: "OTHERS",
    },
    ACTION_ON_DOCUMENT: {
        DELETE: 'DELETE',
        PENDING: 'PENDING',
        RESEND: 'RESEND',
        SIGN: 'SIGN',
        SHARE: 'SHARE',
        COMPLETED: 'completed',
    },
    ACTIVE_MENU: {
        DOCUMENTS: 'document',
        MASSIVE_SIGNATURE: 'massive-signature',
        MASSIVE_SIGNATURE_ENDORSEMENTS: 'massive-signature-endorsements',
        TEMPLATES: 'template',
        PROFILE: 'profile',
        INVOICE: 'invoice',
        USERS: 'users',
        BILLING: 'billing',
        HELP_AND_SUPPORT: 'help-and-support',
        VALIDATION: 'validation',
        TAGS: 'tag',
    },
    AWS: {
        S3_URL: "https://s3.amazonaws.com/",
        EXPIRY_TIME: 518400
    },
    COOKIES_KEY: {
        FILE: 'file',
        DEVICE: 'device',
        USER: 'user',
        DOCUMENT: 'document',
        SESSION_TOKEN: 'sessionToken',
        RETRIEVAL_MAIL: 'retrieval-mail',
        TRACK_MAIL: 'track-mail',
        LANGUAGE: 'englishLang',
        SOCKET: 'socket',
    },
    FIREBASE_ERROR_CODE: {
        ALREADY_EXISTS: "auth/email-already-in-use",
        LIMIT_SUBUSER: "Exceeded limit sub users",
        WRONG_PASSWORD: "auth/wrong-password",
        USER_NOT_FOUND: "auth/user-not-found",
        EMAIL_NOT_VERIFIED: 'auth/email-not-verified',
        RECAPTCHA_ARGUMENT_ERROR: 'auth/argument-error',
        TOO_MANY_REQUEST: 'auth/too-many-requests',
        WRONG_CREDENTIALS: 'auth/wrong-credentials',
        GENERIC_ERROR: 'auth/generic-error',
    },
    ERROR_TYPE: {
        INFO: "info",
        WARNING: "warning",
        ERROR: "error"
    },
    EVENTS: {
        MENU_CHANGED: 'menuChanged'
    },
    API_RESPONSE: {FAILURE: "Server error!"},

    USER_ROLE: {
        USER: 'user',
        SUB_USER: 'sub-user',
    },
    CARD_BRAND_NAME: {
        VISA: 'Visa',
        MASTER_CARD: 'MasterCard',
        AMERICAN_EXPRESS: 'American Express',
        DISCOVER: 'Discover',
    },
    NOTIFICATION_TYPE: {
        ERROR: 'Error',
        SUCCESS: 'Success',
        WARNING: 'Warning',
        INFO: 'Info',
    },
    NOTIFICATION_OPERATION: {
        MASSIVE_SIGNATURE_LIST: {
            MISSING_PENDINGDOCS: 'MISSING_PENDINGDOCS',
        },
        EXPIRE_SESSION: 'EXPIRE_SESSION',
        SUBSCRIPTION_NOW: 'SUBSCRIPTION_NOW',
        PAYMENT_BY_FREEPLAN_EXPIRED: 'PAYMENT_BY_FREEPLAN_EXPIRED',
        PAYMENT_BY_DOCUMENT_LIMIT_EXPIRED: 'PAYMENT_BY_DOCUMENT_LIMIT_EXPIRED',
        PAYMENT_BY_DEBTOR_PLAN: 'PAYMENT_BY_DEBTOR_PLAN',
        PROMO_PLAN_PAYMENT: 'PROMO_PLAN_PAYMENT',
        SUSCRIPTION_REMINDER: 'SUSCRIPTION_REMINDER',
        EXPIRATION_FREE_PLAN_REMINDER: 'EXPIRATION_FREE_PLAN_REMINDER',
        VID_PAYMENT: 'VID_PAYMENT',
        OPEN_KEY_ACTIVATION: 'OPEN_KEY_ACTIVATION',
        ENABLE_SUSCRIPTION_NOW: 'ENABLE_SUSCRIPTION_NOW',
        TEMPLATES_FEATURE_NEW: 'TEMPLATES_FEATURE_NEW',
        TEMPLATE_NEW_COMPLETED: 'TEMPLATE_NEW_COMPLETED',
        DOCUMENT_RESTRICTED: 'DOCUMENT_RESTRICTED',
        ACCESS_RESTRICTED: 'ACCESS_RESTRICTED',
    },
    FIREBASE_ACTION_CODE: {
        VERIFY_EMAIL: "verifyEmail",
        RESET_PASSWORD: "resetPassword",
    },

    DOCUMENT: {
        PENDING: 'PENDING',
        DRAFT: 'DRAFT',
        PROCESSING: 'PROCESSING',
        IMAGE_PROCESSING: 'IMAGE_PROCESSING',
        PENDING_FOR_BLOCK_CHAIN: 'PENDING_FOR_BLOCK_CHAIN',
        COMPLETED: 'COMPLETED',
        IMAGE_PROCESSING_COMPLETED: 'IMAGE_PROCESSING_COMPLETED',
        PENDING_TO_ENDORSEMENT: 'PENDING_TO_ENDORSEMENT',
        ENDORSED: 'ENDORSED',
        CANCELED: 'CANCELED',
    },
    EMAIL_DIALOG_TEMPLATE: {
        SEND_VERIFY_EMAIL: 'sendVerifyMail',
        RE_VERIFY_EMAIL: 'emailNotVerified',
        RESEND_EMAIL_SUCCESS: 'sendVerifyMailSuccess',
        VERIFY_EMAIL: "verifyEmail",
        VERIFY_EMAIL_SUCCESS: 'verifyEmailSuccess',
        VERIFY_EMAIL_FAILURE: 'verifyEmailFailure',

        RESET_PASSWORD_MAIL: "resetPassword",
        RESET_PASSWORD_SUCCESS: "resetPasswordSuccess",
        RESET_PASSWORD_FAILURE: "resetPasswordFailure",

        FORGOT_PASSWORD: 'forgotPassword'
    },
    EMAIL_TEMPLATE:{
        WELCOME_SUB_USER: 'welcomeSubUser'
    },
    TOAST_TYPE: {
        SUCCESS: "success",
        ERROR: "error",
        WARNING: "warning",
        INFO: "info"
    },
    SPANISH_TITLE: "Firmas Electrónicas Avanzadas - weetrust",
    ENGLISH_TITLE: "Secure Electronic Signatures - weetrust",
    CONSTANT_VALUE: {
        MIN_VALUE: 5,
        MAX_VALUE: 18,
        LEAST_VALUE: 14
    },
    DRIFT_WELCOME_MESSAGE_ES: '¿Cómo te podemos ayudar? Por favor deja tu e-mail para que nuestro equipo le de seguimiento a tus preguntas o comentarios. Sigue este vinculo para agendar una llamada: https://drift.me/weetrust/meeting',
    DRIFT_WELCOME_MESSAGE: 'How can we help you? Please leave your email so our team can follow up on your questions / comments. Follow this link to schedule a call with us: https://drift.me/weetrust/meeting',
    DOCUMENT_SOURCE: {
        DOCUMENT: 'DOCUMENT',
        FORM: 'FORM',
    },
};

export const biometricTypes = {
    IMAGE_FRONT: 1,
    IMAGE_BACK: 2,
    IMAGE_FACE: 3,
    IMAGE_PASSPORT: 4,
};

export const identityProviders = {
    VERIDOC: 'VERIDOC',
    INCODE: 'INCODE',
    NEUBARIUM: 'NEUBARIUM',
    VERIDAS: 'VERIDAS',
};

export const identityTypes = {
  ID: 'ID',
  FACE: 'FACE',
};

export const identityDocumentTypes = {
    ID_CARD: 'ID_CARD',
    PASSPORT: 'PASSPORT',
}

export const identityCaptureTypes = {
    DOCUMENT: 'DOCUMENT',
    SELFIE: 'SELFIE',
}

export const identityDocumentSides = {
    FRONT: 'FRONT',
    BACK: 'BACK'
};

export const logConstants = {
    UNKNOWN: 'unknown',
    USER: {
        UPDATE_PERSONAL_DATA: 'UserUpdatedPersonalData',
        ADD_SIGN: 'UserAddedSign',
        CHANGED_SIGN: 'UserChangedSign',
    }
};

export const advancedSearchConstants = {
    EMAIL: {
        ALL: 0,
    },
    STATUS: {
        ALL: 0,
        COMPLETED: 1,
        COMPLETED_WITH_FAILED_BIOMETRIC: 2,
        PENDING: 3,
        PENDING_BECAUSE_FAILED_BIOMETRIC: 4,
        DRAFT: 5,
        SHARED: 6,
        PENDING_TO_SIGN: 7,
    },
    BIOMETRIC: {
        ALL: 0,
        YES: 1,
        NO: 2,
        BG_CHECK: 3,
        INVALID: 4,
        VALID: 5,
    },
    SIGNATURE_TYPE: {
        ALL: 0,
        ELETRONIC_SIGNATURE: 1,
        E_FIRMA: 2,
    },
};

export const staticSignColors = [
    '#B7E3C0',
    '#E4EBFD',
    '#FEE5AD',
    '#EBE6D4',
    '#FFFEE4',
    '#D0ECEA',
    '#FFD8D8',
    '#C5E9F9',
    '#ECD1F4',
    '#D7F6FF'
];

export const formsErrorConstants = {
    REQUIRED: 'forms.validation.errors.required',
    MIN_LENGTH: 'forms.validation.errors.minLength',
    MAX_LENGTH: 'forms.validation.errors.maxLength',
    MIN: 'forms.validation.errors.min',
    MAX: 'forms.validation.errors.max',
    PATTERN: 'forms.validation.errors.pattern',
    PASSWORD_STRENGTH: 'register.password.strength.message',
    EMAIL_INVALID: 'forms.validation.errors.mail.invalid',
    NUMBER: 'forms.validation.errors.number.invalid',
}

export const formsTypeConstants = {
    STEPS_QUESTION: 'STEPS_QUESTION',
    ALL_QUESTION: 'ALL_QUESTION',
}

export const formsTypeFieldConstants = {
    TEXT: 'text',
    EMAIL: 'email',
    NUMBER: 'number',
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
    SELECT_SIMPLE: 'combo',
    SELECT_MULTIPLE: 'combo_multiple',
    DATEPICKER: 'date',
    TIMEPICKER: 'time',
    CONDITIONAL: 'conditional',
    SIGNATURE: 'signature',
    DYNAMIC_TABLE: 'dynamic_table',
}

export const formsFormatsConstants = {
    DATE: {
        DEFAULT_DATE: 'dd/MM/yyyy',
        MONTH_DAY_YEAR: 'MM/DD/YYYY',
        DAY_MONTH_YEAR: 'DD/MM/YYYY',
        FULL_DATE: 'D [de] MMMM [del] YYYY',
        FULL_DAY_MONTH_YEAR: 'DD-MMM-YY',
    },
    TIME: {
        HALF_TIME: 'hh:mm a',
        FULL_TIME: 'HH:mm',
    },
    NUMBER_FORMAT: {
        DEFAULT_NUMBER: /^-?(0|[1-9]\d*)(\.\d+)?$/,
    },
    PHONE: /^[\s()+-]*([0-9][\s()+-]*){11,13}$/,
    EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    STRONG_PASSWORD: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
}

export const formSignatoryConstants = {
    STEPS_STATUS: {
        LOADING: 0,
        RESPONDING: 1,
        REVIEWING: 2,
        PROCESSING: 3,
        COMPLETED: 4,
        REVERSE: 5,
        ERROR: 6,
    },
    STATUS: {
        INITIAL: 'INITIAL',
        PROCESS: 'PROCESS',
        COMPLETED: 'COMPLETED',
        REVERSE: 'REVERSE'
    },
}

export const templatesConstants = {
    TEMPLATE_DOCUMENT_TYPES: {
        MASSIVE: 'MASSIVE',
        SINGLE: 'SINGLE',
    },
}

export const sourceConstants = {
    TYPE: {
        DOCUMENT: 'DOCUMENT',
        FORM: 'FORM',
    },
}

export const monthsConstants = {
    ES: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ],
    EN: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
}

export const fileDocumentConstants = {
    EXTENSION_DEFAULT: 'PDF',
    SIZE_DEFAULT: '0.0MB',
}

export const signatoryLayoutConstants = {
    LARGE_WIDTH_BREAKPOINT: 940,
    HEADER_STICKY_HEIGHT: 70,
    FOOTER_STICKY_HEIGHT: 195,
    FOOTER_HEIGHT: 60,
}

export const layoutConstants = {
    menu: [
        {
            slug: 'document',
            name: 'profile.tab.documents',
            url: '/dashboard/document',
            icon: 'wt-icon-document',
        },
        {
            slug: 'massive',
            name: 'profile.tab.massive.signature',
            icon: 'wt-icon-group-documents',
            submenu: [
                {
                    slug: 'massive-signature',
                    name: 'profile.tab.documents',
                    url: '/dashboard/massive-signature',
                    icon: 'wt-icon-document',
                },
                {
                    slug: 'massive-signature-endorsements',
                    name: 'profile.tab.endorsements',
                    url: '/dashboard/massive-signature/endorsements',
                    icon: 'wt-icon-endorsement-doc',
                },
            ],
        },
        {
            slug: 'template',
            name: 'profile.tab.templates',
            url: '/dashboard/template',
            icon: 'wt-icon-template',
            onboardingTarget: 'TEMPLATES_FEATURE_NEW',
        },
        {
            slug: 'tag',
            name: 'document.page.dropdown.tags',
            url: '/dashboard/tag',
            icon: 'wt-icon-hashtag',
        },
        {
            slug: 'config',
            name: 'profile.tab.config',
            icon: 'wt-icon-settings',
            submenu: [
                {
                    slug: 'profile',
                    name: 'profile.tab.profile',
                    url: '/dashboard/profile',
                    icon: 'wt-icon-user',
                },
                {
                    slug: 'users',
                    name: 'profile.tab.users',
                    url: '/dashboard/users',
                    icon: 'wt-icon-users',
                },
                {
                    slug: 'invoice',
                    name: 'profile.tab.invoice',
                    url: '/dashboard/invoice',
                    icon: 'wt-icon-card',
                },
            ]
        },
        {
            slug: 'validation',
            name: 'profile.tab.validation',
            url: '/dashboard/validation',
            icon: 'wt-icon-check-circle',
        },
    ],
    BREAKPOINT: {
        MOBILE: 640,
        TABLET: 1024,
        SMALL: 768,
    },
}

export const typeTagsConstants = {
    FILTER: 'FILTER',
    PLAIN: 'PLAIN',
    CHECKBOX: 'CHECKBOX',
}

export const stylesTagsConstants = {
    BG_COLOR: 'rgba(0,34,50,.2)',
    TEXT_COLOR: 'rgb(0,34,50)',
}

export const dateRangePickerConstants = {
   DAYS_OF_WEEKS: {
        ES: [
            'Do',
            'Lu',
            'Ma',
            'Mi',
            'Ju',
            'Vi',
            'Sa'
        ],
    },
    SELECT_LABEL: 'Seleccionar',
    CLEAN_LABEL: 'Limpiar',
    SEPARATOR_LABEL: ' - ',
    FROM_LABEL: 'De',
    TO_LABEL: 'a',
    WEEK_LABEL: 'W',
}

export const loginGraphicOptions = {
    TYPE_FIRST: 1,
    TYPE_SECOND: 2,
    TYPE_THIRD: 3,
}

export const notificationDialogType = {
    NOTICE: 'NOTICE',
    DIALOG: 'DIALOG',
    TOAST: 'TOAST',
}

export const bannerDialogType = {
    INFO: 'INFO',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
    PROMO: 'PROMO',
}

export const billingService = {
    GET_PAYMENT_METHODS: '/payments/methods',
    GET_PRODUCT: '/payments/product',
    GET_PRODUCTS: '/products',
    GET_CUSTOMER: '/payments/customer',
    SETUP_INTENT: '/payments/setup/intent',
    PROMOTION_SETUP_INTENT: '/payments/promo-setup/intent',
    PAYMENT_INTENT: '/payments/intent',
    ENABLE_SUSCRIPTION_NOW: '/payments/enable-subscription-now',
    ENABLE_DYNAMIC_MEMBERSHIP: '/payments/enabled-dynamic-membership',
    METHOD_DEFAULT: '/payments/method/default',
    CONFIRM_INTENT: '/payments/confirm/intent',
    GET_SUSCRIPTION_USER: '/subscriptions/user',
    PAY_PRODUCTS: '/payments/pay-products',
    PAYMENT_METHOD: '/payments/method',
    SUBSCRIPTIONS: '/subscriptions'
}

export const stripeTransactionType = {
    CONFIRM_SETUP: 'CONFIRM_SETUP',
    CONFIRM_PAYMENT: 'CONFIRM_PAYMENT',
    CONFIRM_SETUP_PROMO: 'CONFIRM_SETUP_PROMO',
    ADD_NEW_CARD: 'ADD_NEW_CARD',
    RETRY_CONFIRM_PAYMENT: 'RETRY_CONFIRM_PAYMENT',
    PAY_PRODUCTS: 'PAY_PRODUCTS',
}

export const stripeProducts = {
    VID: 'VID',
    BASE_MEMBERSHIP_SUSCRIPTION: 'BASE_MEMBERSHIP',
    WHATSAPP_CREDITS: 'WHATSAPP_CREDITS',
    ATTACHMENT_CREDITS: 'ATTACHMENT_CREDITS',
}

export const paymentSteps = {
    PAYMENT_INIT: 'PAYMENT_INIT',
    PAYMENT_PROCESSING: 'PAYMENT_PROCESSING',
    PAYMENT_ERROR: 'PAYMENT_ERROR',
    PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
    PAYMENT_PROCESSING_WITH_DOCUMENT: 'PAYMENT_PROCESSING_WITH_DOCUMENT',
}

export const plansNamesAlias = {
    TRIAL_1: 'plans.trial.alias',
    BASE_MEMBERSHIP: 'plans.base.alias',
    DYNAMIC_MEMBERSHIP: 'plans.dynamic.alias',
    ZERO: 'plans.zero.alias',
    DEBTOR: 'plans.debtor.alias',
}

export const paymentsStatus = {
    STATUS: {
        INITIAL: 'INITIAL',
        PROCESSING: 'PROCESSING',
        COMPLETED: 'COMPLETED',
        FAILED: 'FAILED'
    },
}

export const invoiceStatus = {
    PAID: 'invoice.status.paid',
    PENDING: 'invoice.status.pending',
    FAILED: 'invoice.status.failed',
    REFUNDED: 'invoice.status.refunded',
}

export const attachmentsConstants = {
    SIDE: {
        FRONT: 'FRONT',
        BACK: 'BACK',
    },
    STEPS: {
        ATTACHMENT_INSTRUCTION: 'ATTACHMENT_INSTRUCTION',
        ATTACHMENT_CAPTURE: 'ATTACHMENT_CAPTURE',
        ATTACHMENT_CONFIRM: 'ATTACHMENT_CONFIRM',
        ATTACHMENT_COMPLETE: 'ATTACHMENT_COMPLETE',
        ATTACHMENT_PROCESSING: 'ATTACHMENT_PROCESSING',
        ATTACHMENT_FAIL: 'ATTACHMENT_FAIL',
    },
}

export const documentsListType = {
    EMPTY_FIRST_LOAD: 'EMPTY_FIRST_LOAD',
    EMPTY_FAIL_LOAD: 'EMPTY_FAIL_LOAD',
    EMPTY_NOT_FOUND: 'EMPTY_NOT_FOUND',
}

export const signUpSteps = {
    REGISTER: 'REGISTER',
    CONFIRM: 'CONFIRM',
    COMPLETE: 'COMPLETE',
    PROCESSING: 'PROCESSING',
};

export const profileStatusCode = {
    CONFLICT: 409,
};

export const signatureConfig = {
    MENU: {
        CREATE_SIGNATURE: 'CREATE_SIGNATURE',
        SAVED_SIGNATURES: 'SAVED_SIGNATURES',
    },
    TYPE_SIGNATURE: {
        TEXT: 'TEXT',
        STROKE: 'STROKE',
    }
}
export const googleTagsEvents = {
    REGISTER_SENT: 'registro_enviado',
    CREDIT_CARD_ADDED: 'tarjeta_agregada',
};

export const orientationDevice = {
    LANDSCAPE: 'landscape',
    PORTRAID: 'portraid',
};

export const documentsStatus = {
    DRAFT: {
        iconClass: 'wt-bullet wt-bullet--draft',
        title: 'document.status.heading.draft',
        slug: 'DRAFT',
    },
    PENDING: {
        iconClass: 'wt-bullet wt-bullet--pending',
        title: 'document.status.heading.pending_signature',
        slug: 'PENDING',
    },
    COMPLETED: {
        iconClass: 'wt-bullet wt-bullet--completed',
        title: 'document.status.heading.completed',
        slug: 'COMPLETED',
    },
    SHARED: {
        iconClass: 'wt-bullet wt-bullet--shared',
        title: 'document.status.heading.shared',
        slug: 'SHARED',
    },
    PENDING_TO_ENDORSEMENT: {
        iconClass: 'wt-bullet wt-bullet--endorsement-pending',
        title: 'document.status.heading.pendingToEndorsement',
        slug: 'PENDING_TO_ENDORSEMENT',
    },
    ENDORSED: {
        iconClass: 'wt-bullet wt-bullet--endorsement-completed',
        title: 'document.status.heading.endorsed',
        slug: 'ENDORSED',
    },
    REFUSED: {
        iconClass: 'wt-bullet wt-bullet--refused',
        title: 'document.status.heading.refused',
        slug: 'REFUSED',
    },
    CANCELED: {
        iconClass: 'wt-bullet wt-bullet--canceled',
        title: 'document.status.heading.canceled',
        slug: 'CANCELED',
    },
};

export const endorsementsStatus = {
    PENDING: {
        iconClass: 'wt-bullet wt-bullet--endorsement-validation',
        title: 'document.status.heading.pendingToEndorsementValidation',
        slug: 'PENDING',
    },
    APPROVED: {
        iconClass: 'wt-bullet wt-bullet--endorsement-pending',
        title: 'document.status.heading.pendingToEndorsement',
        slug: 'APPROVED',
    },
    COMPLETED: {
        iconClass: 'wt-bullet wt-bullet--endorsement-completed',
        title: 'document.status.heading.endorsed',
        slug: 'COMPLETED',
    },
};

export const onboardingStorage = {
    TOUR: 'onboarding',
    NOTIFICATIONS: 'onboardingNotifications',
};

export const onboardingTourTargets = {
    TEMPLATES_FEATURE_NEW: 'TEMPLATES_FEATURE_NEW',
    TEMPLATES_PRELOAD: 'TEMPLATES_PRELOAD',
    VOBO_PREVIEW: 'VOBO_PREVIEW',
    WHATSAPP_FEATURE: 'WHATSAPP_FEATURE',
};

export const templatesConfig = {
    VIEW_VARS: 'VIEW_VARS',
    VIEW_SIGNATORY_FORM: 'VIEW_SIGNATORY_FORM',
    TRANSACTION_TYPE: {
        CREATE: 'create',
        EDIT: 'edit',
        UPDATE: 'update',
    },
    TEMPLATE_TYPE: {
        OLD_TEMPLATE: 'static',
        CONFIGURABLE_TEMPLATE: 'configurable',
        DEFAULT_TEMPLETE: 'default',
    },
};

export const voBoConfig = {
    STATUS: {
        INITIAL: 'INITIAL',
        PENDING: 'PENDING',
        PENDING_WITH_COMMENTS: 'PENDING_WITH_COMMENTS',
        ACCEPTED: 'ACCEPTED',
        REFUSED: 'REFUSED',
    },
};

export const cancelationStatus = {
    PENDING : 'PENDING',
    APPROVED : 'APPROVED',
    REJECTED : 'REJECTED',
};

export const endorsementsConfig = {
    ERROR_MESSAGES: {
        CANC_008: 'document.cancel.exist.error',
        ENDO_008: 'endorsements.exist.error',
    },
};

export const permissions = {
    DOCUMENT_CREATE: 'document:create',
    DOCUMENT_PREVIEW: 'document:preview',
    TEMPLATE_DASHBOARD: 'template:dashboard',
    TEMPLATE_DOCUMENT_CREATE: 'template:document:create',
};

export const routesBlockedByPermissions = [
    {
        url: '/dashboard/template',
        allowed: [permissions.TEMPLATE_DASHBOARD],
    },
    {
        url: '/template',
        allowed: [permissions.TEMPLATE_DOCUMENT_CREATE],
    },
    {
        url: '/document/preview',
        allowed: [permissions.DOCUMENT_PREVIEW],
    },
];

export const sentryError = {
  DOCUMENT_NEGATIVE_COORDINATE: 'DocumentNegativeCoordinate',
  SAVING_IMAGE_FROM_S3: 'SavingImageFromS3',
};
